import 'bootstrap'

window.onload = function () {
  document.querySelector('.contact-form').addEventListener('submit', function(event) {
    event.preventDefault()
    emailjs.sendForm(window.config.emailjs.service, 'template_fuest', this, 'user_xpnk94Lx6hYfaNrXy9fLP')
      .then(function() {
        document.querySelector('.feedback-message-success').classList.remove('d-none')
      }, function(error) {
        document.querySelector('.feedback-message-danger').classList.remove('d-none')
      })

      window.scrollTo(0, document.getElementById('form-top-text').offsetTop - document.getElementById('header-fuest').offsetHeight)
    document.querySelector('.contact-form').reset()
  })
}
